import queryString from "query-string";
import { useLayoutEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BTN_LOGIN_URL,
  BTN_REGISTER_URL,
  DEFAULT_URL,
  DOMAINS_USE_STATIC_URL,
  FallbackRefCode,
  RefCodeMap,
} from "../constants";

const addParams = (url: string, params: queryString.ParsedQuery<string | undefined>) => {
  const [baseUrl, query] = url.split("?");
  const parsedParams = queryString.parse(query as string);
  const newParams = { ...parsedParams, ...params };
  return `${baseUrl}?${queryString.stringify(newParams)}`;
};

const useUrls = () => {
  const [urls, setUrls] = useState(["", "", FallbackRefCode[0]]);
  const [params] = useSearchParams();

  const fbcid = useMemo(() => {
    const fbcidParam = params.get("fbcid");

    try {
      const fbcidLocal = localStorage.getItem("fbcid");

      if (fbcidParam && fbcidLocal !== fbcidParam) {
        localStorage.setItem("fbcid", fbcidParam);
      }

      return fbcidParam ?? fbcidLocal ?? null;
    } catch (error) {
      return fbcidParam ?? null;
    }
  }, [params]);

  useLayoutEffect(() => {
    const hostname = window.location.hostname;
    const [refCode, pixelId] = RefCodeMap[hostname] ?? FallbackRefCode;

    const code = params.has("code") ? params.get("code") ?? refCode : refCode;
    const pixel = params.has("pixel") ? params.get("pixel") ?? pixelId : pixelId;

    if (DOMAINS_USE_STATIC_URL.includes(hostname)) {
      let loginUrl = BTN_LOGIN_URL;
      let registerUrl = BTN_REGISTER_URL;

      const voluumCode = params.has("code") ? params.get("code") ?? undefined : undefined;

      const voluumParams = {
        id: voluumCode,
        fb_dynamic_pixel: typeof pixel === "string" ? pixel : undefined,
        fbcid: fbcid ? fbcid : undefined,
      };

      loginUrl = addParams(BTN_LOGIN_URL, { ...voluumParams });
      registerUrl = addParams(BTN_REGISTER_URL, { ...voluumParams });

      setUrls([loginUrl, registerUrl, code]);
      return;
    }

    const urlParams = {
      id: code,
      fb_dynamic_pixel: typeof pixel === "string" ? pixel : undefined,
      fbcid: fbcid ? fbcid : undefined,
    };

    const loginUrl = addParams(DEFAULT_URL, { ...urlParams, action: "login" });
    const registerUrl = addParams(DEFAULT_URL, {
      ...urlParams,
      action: "register",
    });

    setUrls([loginUrl, registerUrl, code]);
  }, [params, fbcid]);

  return {
    loginUrl: urls[0] as string,
    registerUrl: urls[1] as string,
    refCode: urls[2] as string,
  };
};

export default useUrls;
