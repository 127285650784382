import { VisuallyHidden } from "@99tech/ui";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EnterSite, Equipments, FloatingLineButton, Gamebanners, Logo } from "src/assets";
import { createStyles, isMobile } from "src/main/utils";

interface LoginProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LoginProps> = (props) => {
  const [offsetRight, setOffsetRight] = useState(window.innerWidth > 600 ? (window.innerWidth - 600) / 2 : 20);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setOffsetRight(window.innerWidth > 600 ? (window.innerWidth - 600) / 2 : 20);
    });
  }, []);

  const maxWidth = window.innerWidth > 600 ? 600 : window.innerWidth;
  const lineRightTranslate = window.innerWidth > 600 ? 0 : Math.ceil((maxWidth / 3) * 0.15);

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">Moo555</VisuallyHidden>
      <Box sx={styles.container}>
        <img
          alt="moo555 brand logo"
          src={Logo}
          width={200}
          style={{
            alignSelf: "center",
          }}
        />

        <img
          alt="enter site"
          src={EnterSite}
          width={280}
          style={{
            alignSelf: "center",
            marginTop: -10,
            zIndex: 1,
          }}
        />

        <img
          alt="equipments"
          src={Equipments}
          width={320}
          style={{
            zIndex: 0,
            marginTop: -60,
            alignSelf: "center",
          }}
        />

        <img
          alt="game banners"
          src={Gamebanners}
          width={320}
          style={{
            alignSelf: "center",
            marginTop: -75,
            zIndex: 0,
          }}
        />
      </Box>

      <Box
        sx={{
          position: "fixed",
          height: "max(12vh, 120px)",
          top: "80%",
          right: offsetRight,
          justifyContent: "right",
          transform: `translateX(${lineRightTranslate}px)`,
        }}
      >
        <Box
          display="flex"
          sx={{
            height: "100%",
            width: "100%",
            justifyContent: "flex-end",
            cursor: "pointer",
            zIndex: 10,
          }}
        >
          <img
            onClick={() => window.open("https://m555th.com/cs", "_blank", isMobile() ? "width=full,height=full" : "")}
            alt="fab line contact us"
            src={FloatingLineButton}
            style={{
              maxHeight: "100%",
              maxWidth: "100px",
              alignSelf: "flex-end",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "60px",
  },
});

export default LandingPage;
