import { AppBarProps, Box, BoxProps, Toolbar, styled } from "@mui/material";
import React, { MouseEvent, useCallback, useContext } from "react";
import { BrandLogo, TopLoginButton, TopRegisterButton } from "src/assets";
import { AppContext } from "src/main/constants";
import useGtmTracker, { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles, isMobile, joinSx } from "src/main/utils";

interface TopBarProps extends AppBarProps {}

const LoginImgBox = styled(Box)<BoxProps<"a">>(() => {
  const winWidth = window.innerWidth;
  let newWidth = "90px";
  if (winWidth < 360) {
    newWidth = parseInt(Math.ceil(winWidth / 3) + "") + "px";
  }

  return {
    "@keyframes glowLogin": {
      "0%": {
        opacity: 1,
      },
      "50%": {
        opacity: 0.7,
      },
      "100%": {
        opacity: 1,
      },
    },
    img: {
      width: newWidth,
      animation: "glowLogin 2s ease infinite",
      transition: "2s",
    },
    display: "flex",
  };
});

const RegisterImgBox = styled(Box)<BoxProps<"a">>(() => {
  const winWidth = window.innerWidth;
  let newWidth = "90px";
  if (winWidth < 360) {
    newWidth = parseInt(Math.ceil(winWidth / 3) + "") + "px";
  }

  return {
    "@keyframes glowRegister": {
      "0%": {
        opacity: 0.7,
      },
      "50%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0.7,
      },
    },
    img: {
      width: newWidth,
      animation: "glowRegister 2s ease infinite",
      transition: "2s",
    },
    display: "flex",
  };
});

const TopBar: React.FC<TopBarProps> = (props) => {
  const { sendEvent } = useGtmTracker();
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register", sideAction?: boolean) =>
      (e: MouseEvent<HTMLDivElement> & MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (type === "login") {
          if (sideAction) {
            sendEvent(EvtAction.ClickLogin);
          }
          window.open(loginUrl, "_blank", isMobile() ? "width=full,heigh=full" : "");
        } else {
          if (sideAction) {
            sendEvent(EvtAction.ClickRegister);
          }
          window.open(registerUrl, "_blank", isMobile() ? "width=full,heigh=full" : "");
        }
      },
    [loginUrl, registerUrl, sendEvent],
  );

  return (
    <Toolbar
      disableGutters
      sx={joinSx(styles.toolbar, { height: "8vh" })}
    >
      <img
        alt="top bar brand logo"
        src={BrandLogo}
        style={{ height: "100%", objectFit: "contain" }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          pt: 1,
        }}
      >
        <LoginImgBox
          component="a"
          href={loginUrl}
          onClick={handleClick("login", true)}
          target="_blank"
        >
          <img
            alt="top login"
            src={TopLoginButton}
          />
        </LoginImgBox>
        <RegisterImgBox
          component="a"
          href={registerUrl}
          onClick={handleClick("register", true)}
          target="_blank"
        >
          <img
            alt="top register"
            src={TopRegisterButton}
          />
        </RegisterImgBox>
      </Box>
    </Toolbar>
  );
};

const styles = createStyles({
  toolbar: {},
});

export default TopBar;
